import styles from "../../styles/styles.module.scss";
import React, { useEffect } from "react";

import format from "date-fns/format";
import isSameDay from "date-fns/isSameDay";

import ruLocale from "date-fns/locale/ru";
import enLocale from "date-fns/locale/en-US";

import { Transaction } from "../Transaction";

import { useInView } from "react-intersection-observer";
import { DotsLoader } from "../Loader";

import i18n from "../../i18n";
import { ScrollUpButton } from "../ScrollUpButton";

const { Scrollbars } = require("react-custom-scrollbars");

const LazyLoader = ({ onEndReached = () => null }) => {

  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 1
  });

  useEffect(() => {
    if (inView) {
      onEndReached();
    }
  }, [inView, onEndReached]);

  return (
    <div ref={ref}/>
  );
};

const HistoryTab = ({ txs = [], isLoadingMore, onEndReached = () => null }: any) => {
  return (
    <>
      <Scrollbars
        id={"txs_list"}
        renderTrackVertical={({ ...props }) =>
          <div {...props} className={styles.trackVertical}/>
        }>
        <div className={styles.list} id={"txs"}>
          {txs.map((tx: any, index: number) => {
            return (
              <React.Fragment key={index}>
                {index === 0 || (txs[index - 1] && !isSameDay(tx.block_ts / 1000, txs[index - 1].block_ts / 1000)) ?
                  <div className={styles.header}>{format(tx.block_ts / 1000, "d MMMM", {
                    locale: i18n.language === "ru" ? ruLocale : enLocale
                  })}</div> : null}
                <Transaction tx={tx}/>
              </React.Fragment>
            );
          })}
        </div>
        {isLoadingMore ? <DotsLoader/> : null}
        <LazyLoader onEndReached={onEndReached}/>
        <ScrollUpButton/>
      </Scrollbars>
    </>
  );
};

export { HistoryTab };
