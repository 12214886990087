import axios from "axios";

// const isDevelopment = process.env.NODE_ENV === "development";
const isDevelopment = false;

const WALLET_BASE_URL = isDevelopment ? "http://localhost:5050/-/" : "/-/";

const client = axios.create({
  baseURL: isDevelopment ? "http://localhost:5050/rest" : "/rest"
});

const walletClient = axios.create({
  baseURL: WALLET_BASE_URL
});

const auth = async (login: string, password: string) => {
  const p = new URLSearchParams();
  p.append("login", login);
  p.append("pass", password);
  const res = await walletClient.post(`wallet-auth`, { login, pass: password });
  if (res && res.data && res.status === 200) {
    return res.data;
  }
  return null;
};

const getAddressInfo = async (address: string) => {
  try {
    const res = await client.get(`/address/${address}`);
    if (!res || !res.data) {
      return null;
    }
    return res.data;
  } catch {
    return null;
  }
};

const getTxs = async (address: string, order: string = "desc", limit?: number, offset?: string) => {
  const p = new URLSearchParams();
  p.append("address", address);
  p.append("order", order);
  if (limit) {
    p.append("limit", limit.toString());
  }
  if (offset) {
    p.append("offset", offset);
  }
  const res = await client.get(`/txs/?${p.toString()}`);
  return res.data;
};

const transfer = async ({ login, password }: { login: string, password: string }, address: string, amount: number, comment?: string) => {
  const p = new URLSearchParams();
  p.append("login", login);
  p.append("pass", password);
  p.append("to", address);
  p.append("amount", amount.toString());
  p.append("comment", comment || "");

  // @ts-ignore
  const txString = await makeTransfer(`${login}::${password}`, amount.toString(), address, comment || "", "0");
  // console.info(txString);

  return walletClient.get(`/wallet-put-tx?tx=${txString}`);
};

const getUserInfo = async (nick: string) => {
  const p = new URLSearchParams();
  p.append("nick", nick);
  return walletClient.get(`/user-info?${p.toString()}`);
};

const getQiwiTariffs = async () => {
  return walletClient.get(`/qiwi/tariffs`);
};

const getTariffs = async ({ sess, genBtcAddr }: any = {}) => {
  const formData = new FormData();

  sess && formData.set("sess", sess);
  genBtcAddr && formData.set("gen-btc-addr", genBtcAddr);

  // return {
  //   data: [
  //     { id: "qiwi", price: 0.005, min_amount: 1 },
  //     { id: "cards", price: 0.005, min_amount: 1 },
  //     { id: "btc", price: 0.005, min_amount: 1 }
  //   ]
  // };

  return walletClient.get("qiwi/tariffs");

  // return await new Request(Request.getURL("payment/tariffs"), {
  //   method: "POST",
  //   body: formData
  // });
};

const getUserIcon = (user: string) => {
  const p = new URLSearchParams();
  p.append("user", user);
  return WALLET_BASE_URL + `user-icon?${p.toString()}`;
};

export { auth, getAddressInfo, getTariffs, getQiwiTariffs, getUserInfo, getTxs, transfer, getUserIcon };
