import React from "react";

import styles from "./styles.module.scss";
import cx from "classnames";

import i18n from "../../i18n";
import { useTranslation } from "react-i18next";

import { changeLanguagee } from "../../lib/helpers";

const SettingsTab = () => {

  const { t } = useTranslation();

  const handleChangeLanguage = (e: any) => {
    changeLanguagee(e.target.value);
  };

  return (
    <div className={styles.inner}>

      <section className={styles.section}>

        <div className={"title is-5"}>
          {t("languages.title")}
        </div>

        <div className={cx("control", styles.control)}>
          <label className="radio">
            <input
              onChange={handleChangeLanguage}
              type="radio"
              name="language"
              value={"ru"}
              checked={i18n.language === "ru"}
            />
            {t("languages.ru")}
          </label>
          <label className="radio">
            <input
              onChange={handleChangeLanguage}
              type="radio"
              name="language"
              value={"en"}
              checked={i18n.language === "en"}
            />
            {t("languages.en")}
          </label>
        </div>

      </section>

    </div>
  );
};

export { SettingsTab };
