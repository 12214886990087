import React from "react";

import cx from "classnames";

import styles from "../../styles/styles.module.scss";

import { Text } from "../Text";
import { sum } from "../../lib/helpers";
import { DonutLoader } from "../Loader";
import { useStoreActions } from "../../store";
import { useTranslation } from "react-i18next";
import { getUserIcon } from "../../lib/api";
import { BuyCoinsModal } from "../BuyCoinsModal";

const ProfileTab = ({ tariffs = [], addressInfo = {}, onPaginate, txs }: any) => {

  const signOut = useStoreActions(actions => actions.user.signOut);
  const setFormOpen = useStoreActions(actions => actions.wallet.setFormOpen);

  const { t } = useTranslation();
  const [isBuyModalOpen, setBuyModalOpen] = React.useState(false);

  const formatAmount = (amount: number) => (sum(amount, undefined, 9, true, true, false) || "").split(".").map((n, i) => i === 0 ?
    <span key={i}>{n}.</span> :
    <small key={i}>{n}</small>);

  const [showBuy, setShowBuy] = React.useState(true);
  // React.useEffect(() => {
  //   if (window.location && window.location.search) {
  //     const params = new URLSearchParams(window.location.search);
  //     if (params.get("showBuy")) {
  //       setShowBuy(true);
  //     }
  //   }
  // }, []);

  if (!addressInfo.address) {
    return (
      <div className={styles.loader}>
        <DonutLoader/>
      </div>
    );
  }

  return (
    <>

      <div className={styles.profile}>

        <BuyCoinsModal
          tariffs={tariffs}
          address={addressInfo.address}
          isOpen={isBuyModalOpen}
          onCancelClick={() => setBuyModalOpen(false)}
        />

        <div className={styles.uIcon}>
          <img alt={"Avatar"} src={getUserIcon(addressInfo.address)}/>
        </div>

        <dl className={styles.details}>
          <Text
            id="user-nick"
            value={`@${addressInfo.user_nick}`}
            label={t("user.nickname")}
          />
          <Text
            id="user-address"
            value={`${addressInfo.address}`}
            label={t("user.address")}
          />
          <Text
            id="user-balance"
            value={sum(addressInfo.balance)}
            label={t("user.current_balance")}
          >
            {formatAmount(addressInfo.balance)} MDC
          </Text>
        </dl>

        <div className={cx(styles.buttons)}>
          <a
            className={cx("button is-light is-rounded")}
            onClick={() => {
              setFormOpen(true);
            }}
          >
            {t("send_coins")}
          </a>
          {showBuy ?
            <>
              <br/>
              <a
                className={cx("button is-light is-rounded")}
                onClick={() => {
                  setBuyModalOpen(true);
                }}
              >
                {t("buy.buy_mdc")}
              </a>
            </> : null}
          <br/>
          <button
            className={cx("button is-light is-rounded")}
            onClick={async () => {
              await signOut();
            }}
          >
            {t("menu.sign_out")}
          </button>
        </div>

      </div>
    </>
  );
};

export { ProfileTab };
