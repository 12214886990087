import React from "react";
import ReactDOM from "react-dom";
import cx from "classnames";

import styles from "./styles.module.scss";

import FullScreen from "mobile-safari-fullscreen";
import modalStyles from 'mobile-safari-fullscreen/dist/index.module.css'

const modalRoot = document.getElementById("modal-root");


class BaseModal extends React.Component {

  el: any;

  constructor(props) {
    super(props);
    this.el = document.createElement("div");
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.el
    );
  }
}

const Modal = ({ children, isOpen, className, innerClassName, contentClassName, onClose }: {
  children: any,
  onClose?: any,
  className?: any,
  contentClassName?: any,
  innerClassName?: any,
  isOpen?: boolean
}) =>
  <BaseModal>
    <FullScreen classNames={modalStyles} isOpen={isOpen}>
      <div className={cx(styles.modal, "modal", {
        "is-active": isOpen
      }, className)}>
        <div className={cx("modal-background", styles.background)}></div>
        <div className={cx("modal-content", styles.content, contentClassName)}>
          <div className={cx(styles.inner, innerClassName)}>
            {children({ onClose, isOpen })}
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={onClose}
        />
      </div>
    </FullScreen>
  </BaseModal>;

export { Modal };
