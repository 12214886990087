import i18n from "../i18n";
import {gradientColorData} from "./Constants";

const SUM_DEFAULT_FRACT_DIGITS = 3;
const TICKER_SYMBOL = "MDC";

export function isNumeric(n: any) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function convertExponentialToDecimal(exponentialNumber: number) {
  // sanity check - is it exponential number
  const str = exponentialNumber.toString();
  if (str.indexOf("e") !== -1) {
    const exponent = parseInt(str.split("-")[1], 10);
    // Unfortunately I can not return 1e-8 as 0.00000001, because even if I call parseFloat() on it,
    // it will still return the exponential representation
    // So I have to use .toFixed()
    const result = exponentialNumber.toFixed(exponent);
    return result;
  } else {
    return exponentialNumber;
  }
}

export function sum(
  nanoCoins = 0,
  withSign = false,
  fractDigits = SUM_DEFAULT_FRACT_DIGITS,
  hideText = false,
  triad = true,
  removeTrailingZeroes = true
) {
  const parser =
    fractDigits > SUM_DEFAULT_FRACT_DIGITS
      ? (val: any) => val
      : (val: any) => parseFloat(val).toFixed(SUM_DEFAULT_FRACT_DIGITS);
  let sign = "";
  if (!isNumeric(nanoCoins)) {
    return null;
  }
  if (nanoCoins < 0) {
    sign = "−";
    nanoCoins = -nanoCoins;
  } else if (withSign) {
    sign = "+";
  }
  const suffix = hideText ? "" : ` ${TICKER_SYMBOL}`;
  let value =
    fractDigits > SUM_DEFAULT_FRACT_DIGITS
      ? convertExponentialToDecimal(parser(nanoCoins / 1e9).toFixed(fractDigits))
      : parser(nanoCoins / 1e9).toLocaleString("ru");

  if (triad) {
    if (nanoCoins > 10000 && removeTrailingZeroes) {
      value = parseFloat(value);
    }
    value = value.toString().replace(/(\d)(?=(\d{3})+(\D|$))/g, "$1 ");
  }
  return sign + value + suffix;
}

export const getExplorerLink = (link: string) => `https://explorer.mediacoin.net/${link}`;

export const changeLanguagee = (lng: string) => {
  localStorage.setItem("lang", lng);
  i18n.changeLanguage(lng);
};

function getRandomItem(seed: any, arr: any[]) {
  if (!seed || !seed.split) {
    return arr[Math.floor(Math.random() * arr.length)];
  }
  return arr[
  seed
    .split("")
    .map((ch: any) => ch.charCodeAt(0))
    .reduce((curr: any, prev: any) => curr + prev) % arr.length
    ];
}

export function generateRandomGradient(seed: any, palette = gradientColorData) {
  const from = getRandomItem(seed, palette);
  return `linear-gradient(135deg, ${from} 10%, ${from} 100%)`;
}
