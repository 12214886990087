const ENCRYPTION_KEY = 'ytgh40flfsdf908xRFFi43k48fxtvte53457y899SKEYuyhuyzcma24&&^*$@fgvPASqrweyurncpD%430ir34k49&Ge32e62efuh34';

/**
 * https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
 * @param unicodeStr
 * @returns {string}
 */

function base64Encode(unicodeStr) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(encodeURIComponent(unicodeStr).replace(/%([0-9A-F]{2})/g, function(match, p1) {
    return String.fromCharCode("0x" + p1);
  }));
}

function base64Decode(latinStr) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(latinStr).split("").map(function(c) {
    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(""));
}


/**
 * http://evalonlabs.com/2015/12/10/Simple-XOR-Encryption-in-JS/
 * @param text
 * @param key
 * @returns {string}
 */
function XOR(text, key) {
  const kL = key.length;
  return Array.prototype
    .slice.call(text || '')
    .map(function(c, index) {
      return String.fromCharCode(c.charCodeAt(0) ^ key.charCodeAt(index % kL));
    }).join("");
}

const decryptSync = (p) => base64Decode(XOR(p, ENCRYPTION_KEY));
const encryptSync = (str) => XOR(base64Encode(str), ENCRYPTION_KEY);

export { base64Encode, base64Decode, XOR, decryptSync, encryptSync };
