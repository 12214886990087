import { useTranslation } from "react-i18next";
import cx from "classnames";
import styles from "./styles.module.scss";
import React from "react";

export const SendMoneyFormAmountInput = ({
                                           isUnsufficientFunds,
                                           isLoading,
                                           value,
                                           setValue
                                         }: {
  isLoading: boolean,
  isUnsufficientFunds: boolean,
  value: number,
  setValue: any,
}) => {

  const { t } = useTranslation();

  return (
    <div className={cx("field", styles.amountInput)}>
      <label htmlFor="amount" className="label">
        {t("send_amount")}
      </label>

      <div className={styles.input}>
      <input
        id={"amount"}
        className={cx("input is-rounded", {
          "is-danger": isUnsufficientFunds
        })}
        type={"number"}
        disabled={isLoading}
        placeholder={"0.0"}
        value={value}
        onChange={e => setValue(e.target.value)}
      />

      {isUnsufficientFunds ?
        <p className="help is-danger">
          {t('tx.form.amount_error')}
        </p> : null}

      <span className={styles.addon}>MDC</span>
      </div>
    </div>
  );
};
