import React from "react";
import cx from "classnames";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

const SendMoneyFormPassword = ({
                                 onSubmit,
                                 isLoading
                               }: {
  onSubmit: any,
  isLoading: boolean,
}) => {

  const { t } = useTranslation();

  const [password, setPassword] = React.useState("");

  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      onSubmit(e, password);
      console.info(e, password);
    }} className={styles.inner}>

      <div className={cx("field", styles.addressInput)}>
        <label htmlFor="address" className="label">
          {t("password")}
        </label>
        <input
          id={"password"}
          className={cx("input is-rounded")}
          type={"password"}
          autoFocus
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </div>

      <button
        disabled={isLoading}
        type={"submit"}
        className={cx("button is-light is-rounded is-fullwidth", {
          "is-loading": isLoading
        })}
      >
        {t("send_coins")}
      </button>

    </form>
  );
};

export { SendMoneyFormPassword };
