export const reachGoal = (goal) => {
  window.yaCounter46405245 && window.yaCounter46405245.reachGoal && window.yaCounter46405245.reachGoal(goal);
  window.dataLayer && window.dataLayer.push && window.dataLayer.push({ event: goal });
};

export const GOALS = {

  BUY_VIEW_CARDS: "buy_view_cards",
  BUY_VIEW_LIVECOIN: "buy_view_livecoin",
  BUY_VIEW_QIWI: "buy_view_qiwi",
  BUY_COINS_CLICK: "buy_coins_click",
  BUY_CONTACT_SUPPORT: "buy_contact_support",

  BUY_PAYPAL_CUR_RUB: "buy_paypal_currency_rub",
  BUY_PAYPAL_CUR_USD: "buy_paypal_currency_usd",
  BUY_PAYPAL_CUR_EUR: "buy_paypal_currency_eur",
  BUY_PAYPAL_NEXT: "buy_paypal_next",
  BUY_PAYPAL_VIEW_NEXT: "buy_paypal_view_next",

  VIEW_STATS: "view_stats",
  CHANGE_POS: "change_pos",
  VIEW_BUY_MODAL: "view_buy_modal",
  CLICK_LIVECOIN: "click_livecoin",

  DONATE_VIEW: "donate_view",
  DONATE_50: "donate_50",
  DONATE_100: "donate_100",
  DONATE_200: "donate_200",
  DONATE_500: "donate_500",
  DONATE_1000: "donate_1000",

  ONBOARDING_BANNER_SHOW: "onboarding_banner_show",
  ONBOARDING_BANNER_START: "onboarding_banner_start",
  ONBOARDING_BANNER_CLOSE: "onboarding_banner_close",

  ONLINE: "online",

  OPEN_SUPPORT: "open_support",
  SENT_SUPPORT: "sent_support",
  ERROR_SUPPORT: "error_support",

  BOOTSTRAP_START: "bootstrap_start", // implemented
  BOOTSTRAP_FINISH: "bootstrap_finish", // implemented

  SIGN_UP_OPEN: "signup_open", // implemented
  SIGN_UP_CLOSE: "signup_close", // implemented
  SIGN_UP_USERNAME: "signup_username", // implemented
  SIGN_UP_PASSWORD: "signup_password", // implemented
  SIGN_UP_CONFIRM_PASSWORD: "signup_confirmpassword", // implemented
  SIGN_UP_SUBMIT: "signup_submit",
  SIGN_UP_SUCCESS: "signup_success",
  SIGN_UP_ERROR: "signup_error",

  REGISTRATION_START: "registr_1",
  REGISTRATION_PASSWORD: "registr_2",
  REGISTRATION_INVITE: "registr_3",
  REGISTRATION_FINISH: "registr_4",
  REGISTRATION_SUCCESS: "registr_success", // implemented
  REGISTRATION_ERROR: "registr_error", // implemented

  REGISTER_MY_CHANNEL: "registr_mch",
  REGISTER_MY_WALLET: "registr_mw",
  REGISTER_MY_FILES: "registr_mfl",

  OPEN_INVITE_FORM: "invite_form", // not implemented

  CLOSE_APP: "closing", // implemented
  APP_ERROR: "error", // implemented
  APP_ERROR_FATAL: "error_fatal", // implemented
  APP_AUTOSTART: "autostart", // implemented
  APP_FIRST_RUN: "onboarding_finish", // implemented

  APP_UPDATE_START: "update_start", // implemented
  APP_UPDATE_STOP: "update_stop", // implemented
  APP_UPDATE_FINISH: "update_finish", // implemented

  WRONG_PASSWORD: "invalid_pass", // implemented
  REPORT_PROBLEM: "report_bug", // implemented

  VIEW_TOP_CHANNELS: "top_channel", // no
  VIEW_ALL_CHANNELS: "all_channel", // no
  VIEW_CHANNEL: "channel_view", // no
  VIEW_PLAYLIST_LIST: "playlist_list", // no
  VIEW_PLAYLIST: "playlist_view", // implemented
  PREVIEW_PLAYLIST: "playlist_preview", // implemented
  BOOKMARK_PLAYLIST: "playlist_bookmark", // not implemented
  VIEW_FILE: "file_view", // implemented
  DOWNLOAD_FILE: "file_download", // implemented
  DOWNLOAD_PLAYLIST: "pl_download", // implemented
  DOWNLOAD_SUCCESS: "download_success", // implemented
  POST_FILE_COMMENT: "comments", // implemented
  LIKE_FILE: "like_file", // not implemented
  DISLIKE_FILE: "dislike_file", // not implemented
  BOOKMARK_FILE: "file_bookmark", // not implemented
  VIEW_MY_CHANNELS: "my_channel", // no
  VIEW_ADD_CHANNEL_FORM: "add_channel_form", // implemented
  ADD_CHANNEL_SUCCESS: "add_channel_success", // implemented
  VIEW_EDIT_CHANNEL_FORM: "edit_channel", // implemented
  VIEW_EDIT_PROFILE_FORM: "edit_profile", // implemented

  SHARE_FILE: "share_file", // implemented
  SHARE_PLAYLIST: "share_playlist", // implemented
  SHARE_PROFILE: "share_profile", // implemented
  SHARE_PLAYLIST_AFTER_ADD: "share_add",

  SHARE_VK: "share_vk", // implemented
  SHARE_FACEBOOK: "share_FB", // implemented
  SHARE_TWITTER: "share_tw", // implemented
  SHARE_TELEGRAM: "share_tl", // implemented
  SHARE_OK: "share_ok", // implemented

  DELETE_FILE: "delete_file",
  DELETE_PLAYLIST: "delete_pl",
  LOGIN: "log_in",

  OPEN_CHANGE_PASSWORD: "open_change_password",
  SUCCESS_CHANGE_PASSWORD: "success_change_password",

  VIEW_MY_PLAYLIST: "my_playlist", // not implemented
  VIEW_ADD_PLAYLIST_FORM: "add_playlist_form", // not implemented
  ADD_PLAYLIST_SUCCESS: "add_playlist_success", // not implemented
  VIEW_EDIT_PLAYLIST_FORM: "edit_playlist", // not implemented

  VIEW_ADD_FILE_FORM: "add_file_form", // not implemented
  ADD_FILE_SUCCESS: "add_file_success", // not implemented
  VIEW_EDIT_FILE_FORM: "edit_file", // not implemented

  VIEW_HOW_TO_GET_COINS_PAGE: "how_get_coins", // implemented
  INVITE_VKONTAKTE: "invite_vk", // not implemented
  INVITE_FACEBOOK: "invite_fb", // not implemented
  INVITE_OK: "invite_ok", // not implemented

  INVITE_VKONTAKTE_SIDEBAR: "vk_menu", // not implemented
  INVITE_FACEBOOK_SIDEBAR: "fb_menu", // not implemented
  INVITE_VKONTAKTE_USER: "vk_channel", //  not implemented
  INVITE_FACEBOOK_USER: "fb_channel", // not implemented

  SIGN_IN_VKONTAKTE: "entry_vk_menu", // not implemented
  SIGN_IN_FACEBOOK: "entry_fb_menu", // not implemented
  SIGN_IN_OK: "entry_ok_menu", // not implemented
  SEND_INVITE_VKONTAKTE: "send_invite_vk", // not implemented
  SEND_INVITE_FACEBOOK: "send_invite_fb", // not implemented
  SEND_INVITE_OK: "send_invite_ok", // not implemented

  SEND_INVITE_ERROR: "invite_error", // not implemented

  LOAD_FRIENDS_LIST_VKONTAKTE: "load_list_vk", // not implemented
  LOAD_FRIENDS_LIST_FACEBOOK: "load_list_fb", // not implemented
  LOAD_FRIENDS_LIST_OK: "load_list_ok", // not implemented

  LOGIN_VKONTAKTE: "input_vk", // not implemented
  LOGIN_FACEBOOK: "input_fb", // not implemented
  LOGIN_OK: "input_ok", // not implemented

  VIEW_SEND_COINS_FORM: "send_coins", // implemented

  OPEN_EXCHANGE_PAGE: "exchange", // not implemented
  EXCHANGE_YANDEX: "ex_ya", // not implemented
  EXCHANGE_WM: "ex_wm", // not implemented
  EXCHANGE_QIWI: "ex_qiwi", // not implemented

  SETTINGS_BACKGROUND_OFF: "background_off", // implemented
  SETTINGS_ADULT_OFF: "adult_off", // implemented
  SETTINGS_NOTIFICATIONS_OFF: "notice_off", // implemented

  SEND_INVITE: "send_invite", // not implemented
  SEND_INVITE_START: "send_invite_start", // not implemented
  CHANGE_FOLDER: "change_folder", // implemented

  BANNER_INVITE_CHANNEL: "banner_invite_channel", // not implemented
  BANNER_INVITE_WALLET: "banner_invite_wallet", // not implemented
  BANNER_INVITE_REFERRALS: "banner_invite_referrals", // not implemented

  RELOAD_APP: "reload_app",

  SUBSCRIPTIONS_SHOW_WINDOW: "subscriptions_show_window",
  SUBSCRIPTIONS_SUCCESS_BUY: "subscriptions_success_buy",

  START_DOWNLOAD_SUCCESS: "start_download_success",
  START_DOWNLOAD_ERROR: "start_download_error"
};
