import React from "react";
import TweenFunctions from "tween-functions";
import detectPassiveEvents from "detect-passive-events";

import styles from "./styles.module.scss";

export default class ScrollUp extends React.Component {
  constructor(props) {
    super(props);

    // set default state
    this.state = { show: false };

    // default property `data`
    this.data = {
      startValue: 0,
      currentTime: 0, // store current time of animation
      startTime: null,
      rafId: null
    };

    // this.element = window;

    // bind
    this.handleClick = this.handleClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollStep = this.scrollStep.bind(this);
    this.stopScrolling = this.stopScrolling.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.show !== this.state.show;
  }

  componentDidMount() {
    this.element = document.querySelector("#txs_list > div");
    this.handleScroll(); // initialize state

    // Add all listeners which can start scroll
    this.element.addEventListener("scroll", this.handleScroll);
    this.element.addEventListener(
      "wheel",
      this.stopScrolling,
      detectPassiveEvents.hasSupport ? { passive: true } : false
    );
    this.element.addEventListener(
      "touchstart",
      this.stopScrolling,
      detectPassiveEvents.hasSupport ? { passive: true } : false
    );
  }

  componentWillUnmount() {
    // Remove all listeners which was registered
    this.element.removeEventListener("scroll", this.handleScroll);
    this.element.removeEventListener("wheel", this.stopScrolling, false);
    this.element.removeEventListener("touchstart", this.stopScrolling, false);
  }

  /**
   * Evaluate show/hide this component, depend on new position
   */
  handleScroll() {
    if (this.element.scrollTop > this.props.showUnder) {
      if (!this.state.show) {
        this.setState({ show: true });
      }
    } else {
      if (this.state.show) {
        this.setState({ show: false });
      }
    }
  }

  /**
   * Handle click on the button
   */
  handleClick() {
    this.stopScrolling();
    this.data.startValue = this.element.pageYOffset;
    this.data.currentTime = 0;
    this.data.startTime = null;
    this.data.rafId = window.requestAnimationFrame(this.scrollStep);
  }

  /**
   * Calculate new position
   * and scroll screen to new position or stop scrolling
   * @param timestamp
   */
  scrollStep(timestamp) {
    if (!this.data.startTime) {
      this.data.startTime = timestamp;
    }

    this.data.currentTime = timestamp - this.data.startTime;

    let position = TweenFunctions[this.props.easing](
      this.data.currentTime,
      this.data.startValue,
      this.props.topPosition,
      this.props.duration
    );
    if (this.element.scrollTop <= this.props.topPosition) {
      this.stopScrolling();
    } else {
      this.element.scrollTo(this.element.scrollTop, position);
      this.data.rafId = window.requestAnimationFrame(this.scrollStep);
    }
  }

  /**
   * Stop Animation Frame
   */
  stopScrolling() {
    window.cancelAnimationFrame(this.data.rafId);
  }

  /**
   * Render component
   */
  render() {
    let propStyle = this.props.style;
    let element = (
      <div style={propStyle} onClick={this.handleClick}>
        {this.props.children}
      </div>
    );

    let style = Object.assign({}, ScrollUp.defaultProps.style);
    style = Object.assign(style, propStyle);
    style.opacity = this.state.show ? 1 : 0;
    style.visibility = this.state.show ? "visible" : "hidden";
    style.zIndex = 999;
    style.transitionProperty = "opacity, visibility";

    return React.cloneElement(element, { style: style });
  }
}

ScrollUp.defaultProps = {
  duration: 250,
  easing: "easeOutCubic",
  style: {
    position: "fixed",
    bottom: 32,
    right: 32,
    cursor: "pointer",
    transitionDuration: "0.2s",
    transitionTimingFunction: "linear",
    transitionDelay: "0s"
  },
  topPosition: 0
};

export const ScrollUpButton = ({ under = 240 }) =>
  <ScrollUp showUnder={under}>
    <div className={styles.top}>
      <i className={"fa fa-fw fa-arrow-up"}/>
    </div>
  </ScrollUp>;
