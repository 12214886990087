import React from "react";
import cx from "classnames";
import {useTranslation} from "react-i18next";

import styles from "./styles.module.scss";

import format from "date-fns/format";
import ruLocale from "date-fns/locale/ru";
import enLocale from "date-fns/locale/en-US";

export const SendMoneyFormSuccess = ({ amount, data, onClose, isLoading }: { amount: number, data: any, onClose: any, isLoading: boolean }) => {

  const { t, i18n } = useTranslation();

  return (
    <form className={styles.inner}>
      <div className={styles.success}>
        <i className={"fa fa-check fa-fw"}/>
        <header>
          {t("tx.form.success")}
        </header>
        <div className={styles.info}>
          <span>
          <time>{format(new Date(), "d MMMM yyyy (HH:mm:ss)", {
            locale: i18n.language === "ru" ? ruLocale : enLocale
          })}</time>
          </span>
          <br/>
          <span>
            TxID: <a
            href={`https://explorer.mediacoin.net/tx/0x${data.id}`}
            target={"_blank"}
          >0x{data.id}</a>
          </span>
        </div>
        <button
          disabled={isLoading}
          className={cx("button is-light is-rounded is-fullwidth")}
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        >
          {t("ok")}
        </button>
      </div>
    </form>
  );
};
