import styles from "./styles.module.scss";
import cx from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

export const SendMoneyFormError = ({
                                     onClose,
                                     onRetry,
                                     error
                                   }: { error: boolean | string, onClose: any, onRetry: any }) => {

  const { t } = useTranslation();

  return (
    <form className={cx(styles.inner, styles.error)}>
      <div className={styles.success}>
        <i className={"fa fa-times fa-fw"}/>
        <header>
          {error === "pwd" ? t("tx.form.pwd_error") : t("tx.form.error")}
        </header>
        <button
          className={cx("button is-light is-rounded is-fullwidth")}
          onClick={(e) => {
            e.preventDefault();
            onRetry();
          }}
        >
          {t("try_again")}
        </button>
      </div>
    </form>
  );
};
