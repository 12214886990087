import React from "react";
import cx from "classnames";

import styles from "./styles.module.scss";

import i18n from "../../i18n";

const Paragraph = ({
  className,
  selectable = true,
  error,
  white,
  i18nKey,
  bold,
  regular,
  children,
  fitted,
  semiFitted,
  as,
  xs,
  sm,
  md,
  lg,
  center,
  black,
  primary,
  secondary,
  tertiary,
  pre,
  ...rest
}) => {
  const Tag = as || "p";
  return (
    <Tag
      className={cx(className, styles.paragraph, {
        [styles.notSelectable]: !selectable,
        [styles.black]: black,
        [styles.primary]: primary,
        [styles.secondary]: secondary,
        [styles.tertiary]: tertiary,
        [styles.white]: white,
        [styles.error]: error,
        [styles.xs]: xs,
        [styles.sm]: sm,
        [styles.md]: md,
        [styles.lg]: lg,
        [styles.fitted]: fitted,
        [styles.semiFitted]: semiFitted,
        [styles.center]: center,
        [styles.bold]: bold,
        [styles.regular]: regular,
        [styles.pre]: pre,
      })}
      {...rest}
    >
      {i18nKey ? i18n.t(i18nKey) : children}
    </Tag>
  );
};

export default Paragraph;
