import React from "react";
import cx from "classnames";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { DonutLoader } from "../../components/Loader";
import { useStoreActions } from "../../store";
import { useStoreState } from "easy-peasy";

import { changeLanguagee } from "../../lib/helpers";

import i18n from "../../i18n";

import { reachGoal } from "../../lib/analytics";

import { BuyCoinsModal } from "../../components/BuyCoinsModal";
import { useWasm } from "../../hooks/useWasm";

const AuthPage = () => {

  const { t } = useTranslation();

  const [isUsernameError, setUsernameError] = React.useState(false);
  const [isPwdError, setPwdError] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [checkingPassword, setCheckingPassword] = React.useState(false);

  const checkPassword = useStoreActions(store => store.user.checkPassword);
  const stateUsername = useStoreState(store => store.user.username);

  const {isWasmSupported} = useWasm();

  React.useEffect(() => {
    init();
  }, []);

  const handleChangeLanguage = (lng: string) => {
    changeLanguagee(lng);
  };

  const init = async () => {
    if (stateUsername) {
    } else {
      setLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <DonutLoader/>
      </div>
    );
  }

  return (
    <div className={styles.inner}>

      {/*<BuyCoinsModal isOpen/>*/}

      <form>

        <div className={styles.languages}>
          <span
            onClick={() => handleChangeLanguage("ru")}
            className={cx({
              [styles.active]: i18n.language === "ru"
            })}>
            Ru
          </span>
          <span
            onClick={() => handleChangeLanguage("en")}
            className={cx({
              [styles.active]: i18n.language === "en"
            })}>
            En
          </span>
        </div>

        <div className={styles.logo}>
          <img alt={"Logo"} src={require("../../assets/images/logo_vertical.svg")}/>
          <span>Wallet</span>
        </div>

        <div className={"field"}>

          <label htmlFor={"username"} className="label">
            {t("username")}
          </label>

          <input
            className={cx("input is-rounded", {
              "is-danger": isPwdError
            })}
            id={"username"}
            type={"text"}
            placeholder={t("username")}
            value={username}
            onChange={(e) => {
              if (isUsernameError) {
                setUsernameError(false);
              }
              setUsername(e.target.value);
            }}
          />

          {isUsernameError ?
            <p className="help is-danger">
              {t("error.wrong_password")}
            </p> : null}

        </div>

        <div className={"field"}>

          <label htmlFor={"password"} className="label">
            {t("password")}
          </label>

          <input
            className={cx("input is-rounded", {
              "is-danger": isPwdError
            })}
            id={"password"}
            type={"password"}
            placeholder={t("password")}
            value={password}
            disabled={checkingPassword}
            onChange={(e) => {
              if (isPwdError) {
                setPwdError(false);
              }
              setPassword(e.target.value);
            }}
          />

          {isPwdError ?
            <p className="help is-danger">
              {t("error.wrong_password")}
            </p> : null}

        </div>

        {isWasmSupported ?
          !checkingPassword ?
            <button
              className={cx("button is-link is-rounded")}
              disabled={checkingPassword}
              onClick={async (e) => {
                e.preventDefault();

                await setPwdError(false);
                await setCheckingPassword(true);

                setTimeout(async () => {

                  const res = await checkPassword({ username, password });

                  if (res) {
                    reachGoal("login_w");
                  } else {
                    await setCheckingPassword(false);
                    await setPwdError(true);
                    reachGoal("login_er");
                  }

                }, 100);

              }}
            >
              {checkingPassword ?
                t("checking_password") :
                t("sign_in")}
            </button> :
            <div className={styles.loader}>
              <p>{t("tx.form.key_generating")}</p>
              <DonutLoader>
                <i className={cx("fa fa-fw fa-key", styles.lock)}/>
              </DonutLoader>
            </div> :
          <div className={styles.wasmNotSupported}>
            Unfortunately, your browser is not supported.
          </div>}

      </form>

    </div>
  );
};

export { AuthPage };
