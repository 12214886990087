import { createStore, createTypedHooks } from "easy-peasy";
import model, { StoreModel } from "./model";

const { useStoreActions, useStore, useStoreState, useStoreDispatch } = createTypedHooks<StoreModel>();

export { useStoreActions, useStoreDispatch, useStoreState, useStore };

const store = createStore(model);

export default store;
