import React from "react";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

const Logo = require('../../assets/images/logo.svg');

const TabsList = ({
                    currentPage, setCurrentPage
                  }: any) => {
  const { t } = useTranslation();
  return (
    <nav className={styles.inner}>
      <ul>

        <li
          className={currentPage === 1 ? styles.active : undefined}
          onClick={() => setCurrentPage(1)}
        >
          <i className={"fa fa-fw fa-wallet"}/> <span>{t("menu.history")}</span>
        </li>

        <li
          className={styles.logo}
          onClick={() => setCurrentPage(0)}
        >
          <img alt={"Logo"} src={Logo}/>
        </li>

        <li
          className={currentPage === 2 ? styles.active : undefined}
          onClick={() => setCurrentPage(2)}
        >
          <i className={"fa fa-fw fa-cog"}/> <span>{t("menu.settings")}</span>
        </li>

      </ul>
    </nav>
  );
};

export { TabsList };
