import React from "react";

import cx from 'classnames';

import styles from './styles.module.scss';

const Snackbar = ({children, isOpen}) =>
  <div
    className={cx(styles.container, styles.pos, {
      [styles.open]: isOpen,
      [styles['bottom-center']]: true,
    })}
  >
    {children}
  </div>;

export default Snackbar;

// snackbar-container  snackbar-pos bottom-right
