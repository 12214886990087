import i18n from "i18next";

import { initReactI18next } from "react-i18next";

const ru = require("./i18n/ru.yaml");
const en = require("./i18n/en.yaml");

const localStorageLanguage = localStorage.getItem("lang") || "ru";

i18n.use(initReactI18next).init({
  lng: localStorageLanguage,
  fallbackLng: "ru",
  debug: true,
  interpolation: {
    escapeValue: false
  },
  react: {
    wait: true
  },
  resources: {
    ru: { translation: ru },
    en: { translation: en }
  }
});

window.i18nGlobal = i18n;

export default i18n;
