import { decryptSync, encryptSync } from "./crypto";

class EncryptedLocalStorage {

  getItem(key) {
    const value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(decryptSync(value));
    }
    return null;
  }

  setItem(key, value) {
    localStorage.setItem(
      key,
      encryptSync(value)
    );
  }

  removeItem(key) {
    return localStorage.removeItem(key);
  }

}

const encryptedLocalStorage = new EncryptedLocalStorage();

export { encryptedLocalStorage as EncryptedLocalStorage } ;
