import React from "react";
import cx from "classnames";
import { ProfileTab } from "../ProfileTab";
import { HistoryTab } from "../HistoryTab";

import styles from "./styles.module.scss";
import { SettingsTab } from "../SettingsTab";
import { SendMoneyForm } from "../SendMoneyForm";
import { Modal } from "../Modal";
import { useStoreActions, useStoreState } from "../../store";

const ProfileTabs = ({ tariffs = [], addressInfo = {}, onPaginate, txs = [], currentPage, isLoadingMore, loadMoreTxs }: any) => {

  const [[page, direction], setPage] = React.useState([0, 0]);

  const isFormOpen = useStoreState(state => state.wallet.isFormOpen);
  const setWalletFormOpen = useStoreActions(actions => actions.wallet.setFormOpen);

  React.useEffect(() => {
    setPage([currentPage, direction]);
  }, [currentPage, direction]);

  return (
    <div className={styles.tabs}>

      {/*<Header isSticky title={t('menu.profile')}/>*/}

      <div className={cx(styles.tab, styles.profile, {
        [styles.active]: page === 0
      })}>
        <ProfileTab
          tariffs={tariffs}
          key={"profile"}
          addressInfo={addressInfo}
          txs={txs}
          onPaginate={onPaginate}
        />
      </div>

      <div className={cx(styles.tab, styles.history, {
        [styles.active]: page === 1
      })}>
        <HistoryTab
          onEndReached={loadMoreTxs}
          key={"history"}
          txs={txs}
          isLoadingMore={isLoadingMore}
        />
      </div>

      <div className={cx(styles.tab, styles.settings, {
        [styles.active]: page === 2
      })}>
        <SettingsTab/>
      </div>

      <Modal
        isOpen={isFormOpen}
        onClose={() => setWalletFormOpen(false)}
      >
        {({ onClose }: any) =>
          <SendMoneyForm
            isOpen={isFormOpen}
            onClose={onClose}
            addressInfo={addressInfo}
          />}
      </Modal>

    </div>
  );
};

export { ProfileTabs };
