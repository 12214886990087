class LocalStorage {

  static PROMO_BANNERS = "promoBanners";
  static FIRST_RUN = "firstRun";
  static IS_UPDATED = "isUpdated";
  static SETTINGS = "settings";
  static SAVED_USER = "u";
  static SAVED_MEMORY_USER = "savedMemoryUser";
  static SESSION_KEY = "sessionKey";
  static CONTENT_LANGUAGE = "contentLanguage";
  static LANGUAGE = "language";
  static INITIALIZED_VERSION = "initializedVersion";
  static PREFERENCES = "preferences";
  static ACTIVE_ID = "activeId";
  static LAST_TIME_PASSWORD_CHANGE = "lastTimePasswordChange";
  static LAST_STARTUP_DOCUMENT_ID = "lastStartupDocumentId";
  static BUY_COINS_MODAL_TAB = "buyCoinsModalTab";

}

export {LocalStorage};
