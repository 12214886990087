import React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import { getAddressInfo, getUserIcon } from "../../lib/api";
import styles from "./styles.module.scss";

const ADDRESS_REGEXP = /^MDC[a-zA-Z1-9]{31,43}$/;

export const SendMoneyFormAddressInput = ({ isLoading, onChange, address }: { address: string, onChange: any, isLoading: boolean }) => {
  const { t } = useTranslation();
  const [addr, setAddress] = React.useState(address);
  const [user, setUser] = React.useState({
    address: ""
  });
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleChangeEvent = (event1: any) => {
    setAddress(event1.target.value);
    setError(false);
    setUser({} as any);
  };

  const handleBlurEvent = async (event1: any) => {
    await setLoading(true);
    const isAddress = ADDRESS_REGEXP.test(addr);
    let onChangeData = null;

    if (addr) {
      const addressInfo = await getAddressInfo(isAddress || addr.includes("@") ? addr : `@${addr}`);
      if (!addressInfo || !addressInfo.address_memo) {
        if (addr) {
          setError(true);
        }
      } else {
        setUser(addressInfo);
        onChangeData = addressInfo.address_memo;
      }
    }

    onChange(onChangeData);
    await setLoading(false);
  };

  return (
    <div className={cx("field", styles.addressInput)}>
      <label htmlFor="address" className="label">
        {t("address_or_username")}
      </label>
      <input
        id={"address"}
        className={cx("input is-rounded", {
          "is-danger": error
        })}
        type={"text"}
        disabled={loading || isLoading}
        autoFocus
        value={addr}
        onChange={handleChangeEvent}
        onBlur={handleBlurEvent}
      />

      {error ?
        <p className="help is-danger">
          {t("tx.form.address_error")}
        </p> : null}

      {user && user.address ?
        <div className={styles.avatar}>
          <img alt={"Avatar"} src={getUserIcon(user.address)}/>
        </div> : null}
    </div>
  );
};
