import { action, Action, thunk, Thunk } from "easy-peasy";
import { getUserInfo } from "../lib/api";
import { EncryptedLocalStorage } from "../lib/EncryptedLocalStorage";

function hash31(seed: string) {
  if (!seed) return 0;
  let h = 16769023;
  for (let i = seed.length; i >= 0; i--) h = (h * 16777619) ^ (seed.charCodeAt(i) * 481899);
  return Math.abs(h);
}

export interface UserModel {
  data: any;
  username: string | null;
  password: string | null;

  setUser: Action<UserModel, any>;
  setUsername: Action<UserModel, string | null>;
  setPassword: Action<UserModel, string | null>;

  auth: Thunk<UserModel, { username: string, password: string }>;
  checkPassword: Thunk<UserModel, { username: any, password: any }>;
  signOut: Thunk<UserModel>;
}

const encryptedUser = EncryptedLocalStorage.getItem("u");

const user: UserModel = {
  data: encryptedUser || {},
  username: encryptedUser && encryptedUser.nick || null,
  password: null,
  checkPassword: thunk(async (actions, { username, password }, helpers) => {
    let uname = username.trim().toLowerCase().replace("@", "");

    const lsAuthData = EncryptedLocalStorage.getItem("authData") || {};
    const authString = `${username}::${password}`;
    const hashedAuthString = hash31(authString);

    const savedPublicKey = lsAuthData[hashedAuthString] || null;

    let userData, generatedAddress;

    try {
      if (!savedPublicKey) {
        const res = await Promise.all([
          getUserInfo(username),
          // @ts-ignore
          pubkeyBySecret(`${uname}::${password}`)
        ]);
        userData = res[0];
        generatedAddress = res[1];
        lsAuthData[hashedAuthString] = generatedAddress;
        EncryptedLocalStorage.setItem("authData", JSON.stringify(lsAuthData));
      } else {
        const res = await getUserInfo(username);
        userData = res;
        generatedAddress = savedPublicKey;
      }
    } catch (e) {
      return false;
    }

    const user = userData && userData.data;

    const isOk = user && (user.auth === generatedAddress);

    if (isOk) {
      await actions.setUsername(uname);
      await actions.setPassword(password);
      await actions.setUser(user.user);
      EncryptedLocalStorage.setItem("u", JSON.stringify(user.user));
      return user;
    }

    return false;
  }),
  setUser: action((state, data) => {
    state.data = data;
  }),
  setUsername: action((state, uname) => {
    state.username = uname;
  }),
  setPassword: action((state, pwd) => {
    state.password = pwd;
  }),
  auth: thunk(async (actions, payload) => {
    console.info("Trynna authorize");
  }),
  signOut: thunk(async (actions, payload) => {
    await Promise.all([
      actions.setUsername(null),
      actions.setPassword(null),
      actions.setUser({})
    ]);
    EncryptedLocalStorage.removeItem("u");
  })
};

export default user;
