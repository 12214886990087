import React from "react";
import cx from "classnames";

import styles from "./styles.module.scss";
import Snackbar from "../Snackbar";

export function copyToClipboard(el) {
  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    const oldContentEditable = el.contentEditable,
      oldReadOnly = el.readOnly,
      range = document.createRange();

    el.contenteditable = true;
    el.readonly = false;
    range.selectNodeContents(el);

    const s = window.getSelection();
    s.removeAllRanges();
    s.addRange(range);

    el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

    el.contentEditable = oldContentEditable;
    el.readOnly = oldReadOnly;
  } else {
    el.select();
    document.execCommand("copy");
  }
}

function selectText(node) {
  node = document.getElementById(node);

  if (document.body.createTextRange) {
    const range = document.body.createTextRange();
    range.moveToElementText(node);
    range.select();
  } else if (window.getSelection) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    selection.removeAllRanges();
    selection.addRange(range);
  } else {
    console.warn("Could not select text in node: Unsupported browser.");
  }
}

class Text extends React.Component {

  copyTimeout = null;

  state = {
    isCopied: false
  };

  componentWillUnmount() {
    this.copyTimeout && clearTimeout(this.copyTimeout);
  }

  render() {
    const { selectable = true, ellipsis, copyable = true, label, id, value, children } = this.props;
    return (
      <div className={styles.wrapper}>
        {copyable ?
          <input readOnly id={this.props.id} value={value || children}
                 style={{
                   display: "block",
                   position: "absolute",
                   width: "5px",
                   height: 0,
                   padding: 0,
                   opacity: 0,
                   zIndex: -1
                 }}
          /> : null}

        {copyable ?
          <Snackbar isOpen={this.state.isCopied}>
            Текст успешно скопирован
          </Snackbar> : null}
        <div className={styles.label}>
          {label}
        </div>
        <div
          id={`${id}_text`}
          className={cx(styles.text, {
            "selectable": selectable,
            "copyable": copyable,
            "ellipsis": ellipsis
          })}
          onClick={() => {
            if (copyable) {
              copyToClipboard(document.getElementById(this.props.id));
              this.setState({ isCopied: true });
              this.copyTimeout = setTimeout(() => this.setState({ isCopied: false }), 1500);
              selectText(`${id}_text`);
            }
          }}
        >
          {children || value}
        </div>
      </div>
    );
  }

}

export { Text };
