import React from "react";

import styles from "./styles/styles.module.scss";
import { AuthPage } from "./containers/AuthPage";
import { ProfilePage } from "./containers/ProfilePage";

import { StoreProvider, useStoreState } from "easy-peasy";
import store from "./store";

const AppRouter = () => {
  const username = useStoreState(state => state.user.username);
  return (
    username ?
      <ProfilePage/> :
      <AuthPage/>
  );
};

const App: React.FC = () => {
  return (
    <StoreProvider store={store}>
      <div className="container">
        <div className={styles.inner}>
          <AppRouter/>
        </div>
      </div>
    </StoreProvider>
  );
};

export default App;
