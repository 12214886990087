import user, { UserModel } from "./user";
import common, { CommonModel } from "./common";
import wallet, { WalletModel } from "./wallet";

export interface StoreModel {
  user: UserModel;
  common: CommonModel;
  wallet: WalletModel;
};

const model: StoreModel = {
  user, common, wallet
};

export default model;
