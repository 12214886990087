import React, { useCallback, useState } from "react";
import cx from "classnames";
import { Modal } from "../Modal";

import styles from "./styles.module.scss";
import { useStore, useStoreState } from "easy-peasy";
import { AnimatePresence, motion } from "framer-motion";

import Paragraph from "../Paragraph";

import i18n from "../../i18n";
import { GOALS, reachGoal } from "../../lib/analytics";
import { LocalStorage } from "../../lib/LocalStorage";
import { getTariffs } from "../../lib/api";
import { sum } from "../../lib/helpers";

const AnimatedSingleElement = ({ children, className, delay }) =>
  <motion.div
    animate={"open"}
    exit="closed"
    initial="closed"
    className={className}
    variants={{
      open: {
        opacity: 1,
        transition: {
          duration: .3,
          delay: .1 + delay
        }
      },
      closed: {
        opacity: 0
      }
    }}
  >
    {children}
  </motion.div>;

const BuyCoins = ({
                    id,
                    provider = {},
                    buyButtonTitle,
                    onCancelClick,
                    hasActive,
                    coinsValue,
                    setCoinsValue,
                    currencyValue,
                    setCurrencyValue,
                    isLoading,
                    isContactsOpen,
                    setContactsOpen,
                    setLoading,
                    notAvailable,
                    onGenerateClick = () => null,
                    customAppendText,
                    nextStep,
                    amountLabel = i18n.t("buy.in_rub"),
                    fieldInnerClassName
                  }) => {

  const nick = useStoreState(store => store.user.username);

  const [isCreatedInvoice, setCreatedInvoice] = useState(false);
  const [isNextStep, setNextStep] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [invoiceLink, setInvoiceLink] = useState("");

  const coins = sum(coinsValue * 1e9, undefined, undefined, true);
  const currency = sum(currencyValue * 1e9, undefined, undefined, true);

  React.useEffect(() => {
    if (!isCreatedInvoice && !!hasActive) {
      setCreatedInvoice(true);
    }
    if (isCreatedInvoice && !hasActive) {
      setCreatedInvoice(false);
    }
  }, [hasActive, isCreatedInvoice]);

  React.useEffect(() => {
    if (id === "cards") {
      reachGoal(GOALS.BUY_VIEW_CARDS);
    }
    if (id === "qiwi") {
      reachGoal(GOALS.BUY_VIEW_QIWI);
    }
  }, [id]);

  if (isNextStep && typeof nextStep === "function") {
    return nextStep({ setNextStep });
  }

  if (notAvailable && notAvailable !== "custom") {
    return (
      <div className={cx(styles.content, styles.form, styles.notAvailable)}>
        {i18n.t("buy.not_available")}
      </div>
    );
  }

  if (!provider || !provider.price) {
    return (
      <div className={cx(styles.content, styles.form, styles.notAvailable)}>
        {i18n.t("buy.not_available")}
      </div>
    );
  }

  const convertValue = (num, floor) => floor ? Math.floor(num) : Math.ceil(num);

  return (
    <AnimatePresence>
      <AnimatedSingleElement className={cx(styles.content, styles.form)}>
        {isCreatedInvoice ?
          <AnimatedSingleElement delay={0}>
            <i className={cx("fa fa-fw fa-check",
              styles.icon, styles.successIcon)}/>
          </AnimatedSingleElement> : null}
        <AnimatePresence>
          {isCreatedInvoice ?
            <AnimatedSingleElement delay={.1}>
              <div>
                {i18n.t("buy.created_invoice", {
                  currency, coins, price: provider.price
                })}
              </div>
              <div>
                {i18n.t("buy.help_note")}
              </div>
            </AnimatedSingleElement> : null}
        </AnimatePresence>

        {!isCreatedInvoice ?
          <>
            <div className={styles.field}>
            <div className="field">
              <label className="label">{i18n.t("buy.mdc_amount")}</label>
              <div className="control">
                <div className={styles.append}>
                  {"MDC"}
                </div>
                <input
                  type={"number"}
                  disabled={isLoading}
                  className={cx("input is-rounded", styles.exchangeField)}
                  value={currencyValue}
                  maxLength={4}
                  onChange={e => {
                    const { value } = e.target;
                    setCurrencyValue(value);
                    setCoinsValue(convertValue(value * (provider.price)));
                  }}
                />
              </div>
            </div>
            </div>
            <div className={styles.field}>
            <div className="field">
              <label className="label">
                {amountLabel}
              </label>
              <div className="control">
                <div className={styles.append}>
                  {customAppendText || (id === "btc" ? i18n.t("buy.btc") : i18n.t("buy.rub"))}
                </div>
                <input
                  disabled={isLoading}
                  className={cx("input is-rounded", styles.exchangeField)}
                  type={"number"}
                  value={coinsValue}
                  onChange={e => {
                    const { value } = e.target;
                    setCoinsValue(value);
                    setCurrencyValue(convertValue(value * (1 / provider.price), true));
                  }}
                />
              </div>
            </div>
            </div>
          </> : null}

        {provider && (provider.id === "btc" && !provider.addr) ?
          <a
            className={cx("button is-link is-rounded", styles.buyButton)}
          >
            Сгенерировать адрес
          </a> :
          !isCreatedInvoice ?
            <a
              className={cx("button is-link is-rounded", styles.buyButton)}
              target={"_blank"}
              href={`${provider.tpl_link}&account=@${nick}&amount=${coinsValue}&comment=${i18n.t("buy.buying_coins")}`}
              onClick={(event) => {
                reachGoal(GOALS.BUY_COINS_CLICK);
                if (nextStep) {
                  console.info('Has next step', nextStep);
                  event.preventDefault();
                  event.stopPropagation();
                  reachGoal(GOALS.BUY_PAYPAL_NEXT);
                  return setNextStep(true);
                }
                onCancelClick && onCancelClick();
              }}
            >
              {buyButtonTitle}
            </a> :
            <AnimatedSingleElement delay={.2}>
              <a
                target={"_blank"}
                href={invoiceLink || (hasActive && hasActive.link)}
                className={cx("button is-link is-rounded", styles.buyButton)}
                onClick={onCancelClick}
              >
                {i18n.t("buy.go_to_payment")} <i className={"fa fa-fw fa-chevron-right"}/>
              </a>
            </AnimatedSingleElement>}

        {isCreatedInvoice ? null :
          <div className={styles.help}>

            {id === "btc" &&
            provider &&
            provider.addr ?
              <p className={styles.howTo}
                 onClick={() => {
                   // copyToClipboard({ text: provider.addr, m: "user.address" });
                 }}
                 dangerouslySetInnerHTML={{
                   __html: i18n.t("buy.bitcoin.sent_to", {
                     address: provider.addr
                   })
                 }}/> : null}

            <Paragraph
              tertiary
              xs
              fitted
            >
              1 MDC ≈ {provider.price} {provider.currency}
            </Paragraph>

            <Paragraph
              tertiary
              xs
            >
              {i18n.t("buy.min_amount", {
                amount: provider.min_amount,
                currency: provider.currency
              })} <br/> {provider.commission ? `${i18n.t("buy.comission")}: ${provider.commission}%` : ""}
            </Paragraph>

            {provider && provider.id === "cards" ?
              <Paragraph
                tertiary
                xs
                fitted
              >
                {i18n.t("buy.visa.available_countries")}
              </Paragraph> : null}

            {id === "qiwi" ?
              <Paragraph
                tertiary
                xs
                fitted
              >
                {i18n.t("buy.qiwi.available_countries")}
              </Paragraph> : null}

            {id !== "btc" ?
              <Paragraph
                tertiary
                xs
              >
                {i18n.t("buy.help_note1")}
              </Paragraph> : null}

            <Paragraph
              tertiary
              xs
            >
              {i18n.t("buy.help_note2")} <a
              href={"mailto:mediacoin@mediacoin.pro"}
                                            target={"_blank"}
            >
              {i18n.t("buy.help_note_support")}
              </a>
            </Paragraph>
          </div>}
      </AnimatedSingleElement>
    </AnimatePresence>
  );
};

let defaultOpenTab = localStorage.getItem(LocalStorage.BUY_COINS_MODAL_TAB) || "paypal";

const PaypalNextStep = ({ setNextStep, setOpen, amount = 0, currency = {} }) => {
  const nick = useStoreState(store => store.user.username);
  // const copyToClipboard = useA(dispatch => dispatch.utils.copyToClipboard);
  const copyToClipboard = () => null;

  React.useEffect(() => {
    reachGoal(GOALS.BUY_PAYPAL_VIEW_NEXT);
  }, []);

  return (
    <div className={styles.paypalNextStep}>
      <div className={styles.content}>
        <Paragraph
          primary
          xs
          style={{
            alignSelf: "flex-start"
          }}
        >
          {i18n.t("buy.paypal.how_to")}
        </Paragraph>
        <Paragraph
          primary
          xs
        >
          1. <span dangerouslySetInnerHTML={{
          __html: i18n.t("buy.paypal.step1")
        }}/>
          <div
            className={styles.note}>
            <em>
              <a target={"_blank"} href={currency && currency.tpl_link}>
                {currency && currency.tpl_link}
              </a>
            </em>
          </div>
          <br/>
          2. {i18n.t("buy.paypal.step2")}
          <div
            className={styles.note}
            onClick={() => {
              // copyToClipboard({ text: `Buying MDC for @${nick}`, m: "ui.text" });
            }}>
            <em>
              {amount} {currency && currency.currency}
            </em>
          </div>
          <br/>
          3. <span className={{
            fontFamily: "auto",
        }}>⚠</span> {i18n.t("buy.paypal.step3")}:
          <div
            className={styles.note}
            onClick={() => {
              // copyToClipboard({ text: `Buying MDC for @${nick}`, m: "ui.text" });
            }}>
            <em>
              Buying MDC for @{nick}
            </em>
          </div> <br/><br/>
          {i18n.t("buy.paypal.step4")}
        </Paragraph>
        <div
          className={"button is-link is-rounded"}
          onClick={() => {
            setOpen(false);
          }}
        >
          {i18n.t("ui.ok")}
        </div>
      </div>
    </div>
  );
};

const LivecoinContent = ({ address }) =>
  <AnimatedSingleElement>
    <div className={styles.content}>
      <Paragraph
        primary
        xs
        semiFitted
      >
        <span>
          {i18n.t("stat.livecoin.how_to")}
        </span>
        <br/>
        <br/>
        <p className={styles.howTo} dangerouslySetInnerHTML={{
          __html: i18n.t("stat.livecoin.info", {
            address
          })
        }}/>
      </Paragraph>
      <a
        className={"button is-small is-link is-rounded"}
        href={"https://www.livecoin.net/ru/trading/MDC_BTC"}
        target={"_blank"}
        onClick={() => {
          reachGoal(GOALS.CLICK_LIVECOIN);
        }}
      >
        {i18n.t("stat.go_to_livecoin")}
      </a>
      <div className={styles.helpControls}>
        <div
          secondary
          xs
          fitted
        >
          {i18n.t("buy.help_error_note")}
        </div>
      </div>
    </div>
  </AnimatedSingleElement>;

const BuyCoinsModalContent = ({
                                title,
                                okButtonText, cancelButtonText,
                                onOkClick, onCancelClick,
                                onSuccess, onError,
                                initialCoinsValue = 10e3,
                                tariffs = []
                              }) => {

  const [at, sAt] = useState(defaultOpenTab);

  const [isLoading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [activeInvoices, setActiveInvoices] = useState([]);
  const [currentTariffs, setCurrentTariffs] = useState([]);

  const address = useStoreState(store => store.user.data.address);

  const [isOpen, setOpen] = React.useState(false);
  const [currency, setCurrency] = React.useState(null);

  // eslint-disable-next-line
  const getWorkingProvider = useCallback((tariffs) => {
    const qiwi = tariffs.find(v => v.id === "qiwi");
    const visa = tariffs.find(v => v.id === "cards");
    const btc = tariffs.find(v => v.id === "btc");
    if (at === "visa" && visa) {
      return visa;
    }
    if (at === "bitcoin" && btc) {
      return btc;
    }
    if (at === "qiwi" && qiwi) {
      return qiwi;
    }
    return qiwi || visa || btc || {};
  });

  const qiwi = currentTariffs.find(v => v.id === "qiwi");
  const visa = currentTariffs.find(v => v.id === "cards");
  const btc = currentTariffs.find(v => v.id === "btc");
  const paypal = currentTariffs.find(v => v.id === "paypal");

  const paypalTariffs = currentTariffs.filter(v => v.id === "paypal");

  let workingProvider = getWorkingProvider(currentTariffs);

  let initialValue = initialCoinsValue * (workingProvider).price < workingProvider.min_amount ? initialCoinsValue : initialCoinsValue;
  const [coinsValue, setCoinsValue] = useState((initialValue * workingProvider.price).toFixed(0));
  const [currencyValue, setCurrencyValue] = useState(initialValue);

  const setValue = (val, provider, toFixed = 0) => {
    if (provider && provider.price) {
      setCoinsValue((val * provider.price).toFixed(toFixed));
      setCurrencyValue(val);
    }
  };

  React.useEffect(() => {
    if (at === "livecoin") {
      reachGoal(GOALS.BUY_VIEW_LIVECOIN);
      defaultOpenTab = "qiwi";
      localStorage.setItem(LocalStorage.BUY_COINS_MODAL_TAB, "qiwi");
    } else {
      defaultOpenTab = at;
      localStorage.setItem(LocalStorage.BUY_COINS_MODAL_TAB, at);
      if (at === "paypal") {
        defaultOpenTab = "paypal";
        localStorage.setItem(LocalStorage.BUY_COINS_MODAL_TAB, "paypal");
      }
    }
    if (at === "visa") {
      setValue(currencyValue, getWorkingProvider(currentTariffs));
    }
    if (at === "bitcoin") {
      setValue(currencyValue, btc, 4);
    }
  }, [at, btc, getWorkingProvider, currencyValue, currentTariffs, workingProvider]);

  React.useEffect(() => {
    if (at === "paypal") {
      initializePaypal(paypalTariffs, currency);
    }
    // eslint-disable-next-line
  }, [at, currency]);

  function initializePaypal(tariffs = [], curr) {
    if (!curr) {
      if (i18n.language === "ru") {
        const ruTariff = tariffs.find((t) => t.currency === "RUB");
        setCurrency(ruTariff || tariffs[0]);
      } else {
        const enTariff = tariffs.find((t) => t.currency === "USD");
        setCurrency(enTariff || tariffs[0]);
      }
      // setValue(currencyValue, tariffs[0]);
    } else {
      setValue(currencyValue, curr);
    }
  }

  React.useEffect(() => {
    getTariffs().then((res) => {
      setCurrentTariffs(res.data);
      setValue(initialCoinsValue, getWorkingProvider(res.data));
      if (at === "paypal") {
        initializePaypal(res.data.filter(v => v.id === "paypal"));
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.inner}>

      <div className={styles.title}>
        <h3 className={styles.h3} fitted>{title}</h3>
        <div
          className={styles.close}
          onClick={onCancelClick}
        />
      </div>

      <div className={styles.wrap}>

        <div className={styles.tabsWrap}>
          <div className={styles.tabs}>

            <div
              className={cx(styles.tab, {
                [styles.disabled]: at !== "paypal" || isLoading
              })}
              onClick={() => isLoading ? null : sAt("paypal")}
            >
              <img alt={"PayPal"} src={require("../../assets/images/buy/paypal.png")}/>
              <strong>PayPal</strong>
              <span>{i18n.t("buy.for.paypal_sub")}</span>
            </div>

            <div
              className={cx(styles.tab, {
                [styles.disabled]: at !== "visa" || isLoading
              })}
              onClick={() => isLoading ? null : sAt("visa")}
            >
              <img alt={"MasterCard"} src={require("../../assets/images/buy/mc.png")}/>
              <strong>Visa/MasterCard</strong>
              <span>{i18n.t("buy.for.card_sub")}</span>
            </div>

            <div
              className={cx(styles.tab, {
                [styles.disabled]: at !== "qiwi" || isLoading
              })}
              onClick={() => isLoading ? null : sAt("qiwi")}
            >
              <img alt={"Qiwi"} src={require("../../assets/images/buy/qiwi.png")}/>
              <strong>QIWI</strong>
              <span>{i18n.t("buy.for.qiwi_sub")}</span>
            </div>

            <div
              className={cx(styles.tab, {
                [styles.disabled]: at !== "livecoin" || isLoading
              })}
              onClick={() => isLoading ? null : sAt("livecoin")}
            >
              <img alt={"Livecoin"} src={require("../../assets/images/buy/livecoin.png")}/>
              <strong>Livecoin</strong>
              <span>{i18n.t("buy.for.livecoin_sub")}</span>
            </div>

            {/*<div*/}
            {/*  className={cx(styles.tab, {*/}
            {/*    [styles.disabled]: at !== "bitcoin" || isLoading*/}
            {/*  }, styles.disabled)}*/}
            {/*  onClick={() => isLoading ? null : sAt("bitcoin")}*/}
            {/*>*/}
            {/*  <img alt={"Bitcoin"} src={require("../../assets/images/buy/bitcoin.svg")}/>*/}
            {/*  <strong>Bitcoin</strong>*/}
            {/*  <span>{i18n.t("buy.for.btc_sub")}</span>*/}
            {/*</div>*/}

            {/*<div*/}
            {/*  className={cx(styles.tab, {*/}
            {/*    [styles.disabled]: at !== "ethereum" || isLoading*/}
            {/*  }, styles.disabled)}*/}
            {/*  onClick={() => isLoading ? null : sAt("ethereum")}*/}
            {/*>*/}
            {/*  <img alt={"Ethereum"} src={require("../../assets/images/buy/ethereum.svg")}/>*/}
            {/*  <strong>Ethereum</strong>*/}
            {/*  <span>{i18n.t("buy.for.eth_sub")}</span>*/}
            {/*</div>*/}

          </div>
        </div>

        {at === "livecoin" ?
          <LivecoinContent
            address={address}
          /> : null}

        {at === "qiwi" ?
          <BuyCoins
            id={"qiwi"}
            notAvailable={i18n.language === "en" ? "custom" : null}
            provider={qiwi}
            buyButtonTitle={i18n.t("buy.with_qiwi")}
            setLoading={setLoading}
            isLoading={isLoading}
            onCancelClick={onCancelClick}
            hasActive={activeInvoices.find((item) => item.tariff === "qiwi")}
            coinsValue={coinsValue}
            currencyValue={currencyValue}
            setCoinsValue={setCoinsValue}
            setCurrencyValue={setCurrencyValue}
            isContactsOpen={isOpen}
            setContactsOpen={setOpen}
          /> : null}

        {at === "paypal" ?
          <BuyCoins
            id={"paypal"}
            provider={currency || paypal}
            buyButtonTitle={i18n.t("buy.with_paypal")}
            setLoading={setLoading}
            isLoading={isLoading}
            onCancelClick={onCancelClick}
            hasActive={activeInvoices.find((item) => item.tariff === "paypal")}
            coinsValue={coinsValue}
            currencyValue={currencyValue}
            setCoinsValue={setCoinsValue}
            setCurrencyValue={setCurrencyValue}
            isContactsOpen={isOpen}
            setContactsOpen={setOpen}
            customAppendText={currency && currency.currency}
            fieldInnerClassName={styles.paypalFieldInner}
            amountLabel={<div className={styles.paypalLabel}>
              {i18n.t("buy.in_paypal")}&nbsp;
              <ul>
                {paypalTariffs.map((t, key) =>
                  <li
                    key={key}
                    onClick={() => {
                      setCurrency(t);
                      setValue(currencyValue, t);
                      if (t.currency === "RUB") {
                        reachGoal(GOALS.BUY_PAYPAL_CUR_RUB);
                      }
                      if (t.currency === "EUR") {
                        reachGoal(GOALS.BUY_PAYPAL_CUR_EUR);
                      }
                      if (t.currency === "USD") {
                        reachGoal(GOALS.BUY_PAYPAL_CUR_USD);
                      }
                    }}
                    className={cx({
                      [styles.active]: currency && (currency.currency === t.currency)
                    })}>
                    {t.currency}
                  </li>)}
              </ul>
            </div>}
            nextStep={({ setNextStep }) =>
              <PaypalNextStep
                amount={coinsValue}
                currency={currency}
                setNextStep={setNextStep}
                setOpen={onCancelClick}
              />
            }
          />
          : null}

        {at === "ethereum" ?
          <BuyCoins notAvailable/>
          : null}

        {at === "bitcoin" ?
          <BuyCoins
            id={"btc"}
            notAvailable={i18n.language === "en" ? "custom" : null}
            provider={btc}
            buyButtonTitle={i18n.t("buy.with_visa")}
            setLoading={setLoading}
            isLoading={isLoading}
            onCancelClick={onCancelClick}
            hasActive={activeInvoices.find((item) => item.tariff === "btc")}
            coinsValue={coinsValue}
            currencyValue={currencyValue}
            setCoinsValue={setCoinsValue}
            setCurrencyValue={setCurrencyValue}
            isContactsOpen={isOpen}
            setContactsOpen={setOpen}
            onGenerateClick={function() {
              getTariffs({ genBtcAddr: 1 }).then((res) => {
                console.info("Generated BTC address");
                setCurrentTariffs(res.data);
                setValue(initialCoinsValue, getWorkingProvider(res.data));
              });
            }
            }
          />
          : null}

        {at === "visa" ?
          <BuyCoins
            id={"cards"}
            notAvailable={i18n.language === "en" ? "custom" : null}
            provider={visa}
            buyButtonTitle={i18n.t("buy.with_visa")}
            setLoading={setLoading}
            isLoading={isLoading}
            onCancelClick={onCancelClick}
            hasActive={activeInvoices.find((item) => item.tariff === "cards")}
            coinsValue={coinsValue}
            currencyValue={currencyValue}
            setCoinsValue={setCoinsValue}
            setCurrencyValue={setCurrencyValue}
            isContactsOpen={isOpen}
            setContactsOpen={setOpen}
          /> : null}

      </div>

    </div>
  );
};

export const BuyCoinsModal = ({
                                title = i18n.t("buy.title"),
                                oKButtonText = i18n.t("ui.send"),
                                cancelButtonText = i18n.t("ui.close"),
                                isOpen = false,
                                ...rest
                              }) =>
  isOpen ?
    <Modal
      className={styles.modal}
      contentClassName={styles.modalContent}
      innerClassName={styles.modalInner}
      width={"auto"}
      isOpen={isOpen}
      onClose={rest.onCancelClick}
    >
      {({ isOpen, onClose }) =>
        <BuyCoinsModalContent
          title={title}
          okButtonText={oKButtonText}
          cancelButtonText={cancelButtonText}
          {...rest}
        />}
    </Modal> : null;
