import React from "react";

import { ProfileTabs } from "../../components/ProfileTabs";
import { TabsList } from "../../components/TabsList";
import { getAddressInfo, getTariffs, getTxs } from "../../lib/api";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";
import { useStoreState } from "easy-peasy";
import { useWasm } from "../../hooks/useWasm";

const Logo = require("../../assets/images/logo_horizontal.svg");

const orderBy = require("lodash/orderBy");

const ProfilePage = ({ history }: any) => {

  const { t } = useTranslation();

  const [addressInfo, setAddressInfo] = React.useState({} as any);
  const [txs, setTxs] = React.useState([]);
  const [txOffset, setTxOffset] = React.useState(null);
  const [tariffs, setTariffs] = React.useState([]);
  const [isLoadingMore, setLoadingMore] = React.useState(false);

  const [isLoading, setLoading] = React.useState(true);
  const [isLoaded, setLoaded] = React.useState(false);

  const username = useStoreState(state => state.user.username);

  const wasm = useWasm();

  React.useEffect(() => {
    if (!username) {
      history.push("/auth");
    } else {
      init();
    }
  }, []);

  const processTx = (tx: any, info: any) => ({
    id: tx.id,
    stype: tx.stype,
    type: tx.type,
    block_ts: tx.block_ts,
    fromUser: tx.sender_address === info.address,

    sender_address: tx.sender_address,
    sender_nick: tx.sender_nick,

    comment: tx.obj && tx.obj.comment,

    obj: tx.sender_address === info.address ?
      tx.obj.outs[0] :
      tx.obj.outs.find((t: any) => (t.address === info.address) || (t.to === info.address)),
    tx: tx.state.find((t: any) => t.address === info.address)
  });

  const init = async () => {
    const info = await getAddressInfo(`@${username}`);
    const txs = await getTxs(`@${username}`);

    const tariffs = await getTariffs();
    await setAddressInfo(info);
    if (tariffs && tariffs.data) {
      // @ts-ignore
      setTariffs(tariffs.data);
    }

    if (txs && txs.results) {
      await setTxOffset(txs.next_offset);
      const allTxs = txs.results && txs.results.map((tx: any) => processTx(tx, info));
      setTxs(allTxs);
    } else {
      setLoaded(true);
    }

    await setLoading(false);

  };

  const loadMoreTxs = async () => {
    if (isLoaded || isLoadingMore || isLoading) {
      return null;
    }
    if (txOffset) {
      await setLoadingMore(true);

      const resTxs = await getTxs(`@${username}`, undefined, undefined, txOffset || undefined);

      if (!resTxs.results) {
        await setLoaded(true);
      } else {
        await setTxOffset(resTxs.next_offset);
        const allTxs = resTxs.results && resTxs.results.map((tx: any) => processTx(tx, addressInfo));
        await setTxs(txs.concat(allTxs));
      }

      await setLoadingMore(false);
    }
  };

  const [currentPage, setCurrentPage] = React.useState(0);

  return (
    <div className={styles.inner} id={"content"}>

      <aside className={styles.panel}>
        <div className={styles.logo}>
          <img src={Logo}/>
          <span>Wallet</span>
        </div>
        <nav className={styles.menu}>
          <ul>
            <li
              onClick={() => setCurrentPage(0)}
              className={currentPage === 0 ? styles.active : undefined}
            >
              <a><i className={"fa fa-fw fa-user"}/> {t("menu.profile")}</a>
            </li>
            <li
              onClick={() => setCurrentPage(1)}
              className={currentPage === 1 ? styles.active : undefined}
            >
              <a><i className={"fa fa-fw fa-wallet"}/> {t("menu.history")}</a>
            </li>
            <li
              onClick={() => setCurrentPage(2)}
              className={currentPage === 2 ? styles.active : undefined}
            >
              <a><i className={"fa fa-fw fa-cog"}/> {t("menu.settings")}</a>
            </li>
          </ul>
        </nav>
      </aside>

      <ProfileTabs
        tariffs={tariffs}
        currentPage={currentPage}
        onPaginate={(arg: any) => {
          setCurrentPage(arg[0]);
        }}
        addressInfo={addressInfo}
        loadMoreTxs={loadMoreTxs}
        isLoadingMore={isLoadingMore}
        txs={orderBy(txs, "-block_ts")}
      />

      <TabsList
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export { ProfilePage };
