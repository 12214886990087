import React, { useState } from "react";
import cx from "classnames";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { SendMoneyFormAmountInput } from "./SendMoneyFormAmountInput";
import { SendMoneyFormAddressInput } from "./SendMoneyFormAddressInput";
import { SendMoneyFormSuccess } from "./SendMoneyFormSuccess";
import { SendMoneyFormError } from "./SendMoneyFormError";
import { transfer } from "../../lib/api";
import { EncryptedLocalStorage } from "../../lib/EncryptedLocalStorage";
import { SendMoneyFormPassword } from "./SendMoneyFormPassword";
import { reachGoal } from "../../lib/analytics";

const SendMoneyForm = ({
                         isOpen,
                         onClose,
                         addressInfo
                       }: {
  isOpen: boolean,
  onClose: any,
  addressInfo: any,
}) => {

  const { t, i18n } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isCheckingPassword, setCheckingPassword] = useState(false);
  const [isError, setError] = useState(false);

  const [value, setValue] = useState(0);
  const [address, setAddress] = useState("");
  const [comment, setComment] = useState("");
  const [password, setPassword] = useState("");

  const [res, setRes] = useState({});

  const isUnsufficientFunds = (value * 1e9) > addressInfo.balance;

  const amount = value * 1e9;

  const encryptedUser = EncryptedLocalStorage.getItem("u");
  const login = encryptedUser && encryptedUser.nick;

  React.useEffect(() => {
    if (!isOpen) {
      setValue(0);
      setAddress("");
      setComment("");
      setPassword("");
      setCheckingPassword(false);
    }
  }, [isOpen]);

  if (isCheckingPassword) {
    return (
      <SendMoneyFormPassword
        isLoading={isLoading}
        onSubmit={async (e: any, pwd: any) => {
          setLoading(true);
          await setPassword(pwd);
          setTimeout(async()=>{
            try {
              const res = await transfer({
                login,
                password: pwd
              }, address, amount, comment);
              if (res && res.data) {
                setRes(res.data);
                reachGoal("transfer_w");
              }
              setSuccess(true);
              setValue(0);
              setAddress("");
              setComment("");
            } catch (e) {
              const isWrongPwd = e.response && e.response.data && e.response.data.includes("auth");
              setError(isWrongPwd ? "pwd" as any : true);
            } finally {
              setLoading(false);
              setCheckingPassword(false);
            }
          }, 199);
        }}
      />
    );
  }

  if (isSuccess) {
    return (
      <SendMoneyFormSuccess
        isLoading={isLoading}
        amount={amount}
        data={res}
        onClose={() => {
          setSuccess(false);
          onClose();
        }}
      />
    );
  }

  if (isError) {
    return (
      <SendMoneyFormError
        error={isError}
        onRetry={() => {
          setSuccess(false);
          setError(false);
        }}
        onClose={() => {
          setSuccess(false);
          setError(false);
          onClose();
        }}
      />
    );
  }

  return (
    <form className={styles.inner}>

      <SendMoneyFormAddressInput
        address={address}
        isLoading={isLoading}
        onChange={(v: React.SetStateAction<string>) => setAddress(v)}
      />

      <SendMoneyFormAmountInput
        isUnsufficientFunds={isUnsufficientFunds}
        isLoading={isLoading}
        value={value}
        setValue={setValue}
      />

      <div className={"field"}>
        <label htmlFor={"comment"} className="label">
          {t("comment")}
        </label>
        <input
          id={"comment"}
          className={"input is-rounded"}
          disabled={isLoading}
          placeholder={t("enter_your_comment")}
          value={comment}
          onChange={e => setComment(e.target.value)}
        />
      </div>

      <button
        disabled={isLoading || !address || !value || isUnsufficientFunds || value < 0}
        type={"submit"}
        className={cx("button is-light is-rounded is-fullwidth", {
          "is-loading": isLoading
        })}
        onClick={async () => {
          if (isLoading || !address || !value || isUnsufficientFunds || value < 0) {
            return null;
          }
          setCheckingPassword(true);
        }}
      >
        {t("send_coins")}
      </button>

    </form>
  );
};

export { SendMoneyForm };
