import { action, Action } from "easy-peasy";

export interface WalletModel {
  isFormOpen: boolean;
  setFormOpen: Action<WalletModel, boolean>;
}

const wallet: WalletModel = {
  isFormOpen: false,
  setFormOpen: action((state, payload) => {
    state.isFormOpen = payload;
  })
};

export default wallet;
