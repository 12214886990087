import React from "react";

import styles from "./styles.module.scss";

const DonutLoader = ({ children = null }: { children?: any }) =>
  <div className={styles.donutLoaderWrapper}>
    <div className={styles.donutLoader}>
    </div>
    {children}
  </div>;

const DotsLoader = () =>
  <div className={styles.dotInner}>
    <div className={styles.dotFlashing}/>
  </div>;

export { DonutLoader, DotsLoader };
