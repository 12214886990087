import cx from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import format from "date-fns/format";

import ruLocale from "date-fns/locale/ru";
import enLocale from "date-fns/locale/en-US";

import styles from "./styles.module.scss";
import { generateRandomGradient, getExplorerLink, sum } from "../../lib/helpers";

import i18n from "../../i18n";
import { getUserIcon } from "../../lib/api";

const EMISSION_TYPES = {
  initial: 0,
  distribution: 1,
  author: 2,
  ref: 3
};

const getEmissionTypeTitle = (type: any) => {
  switch (type) {
    case EMISSION_TYPES.initial:
      return i18n.t("tx.emission_type.initial");
    case EMISSION_TYPES.distribution:
      return i18n.t("tx.emission_type.distribution");
    case EMISSION_TYPES.author:
      return i18n.t("tx.emission_type.author");
    case EMISSION_TYPES.ref:
      return i18n.t("tx.emission_type.ref");
    default:
      return i18n.t("tx.emission_type.tx");
  }
};

const Transaction = ({ tx }: any) => {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const formatAmount = (amount: number) => (sum(amount, undefined, 9, true, true, false) || "").split(".").map((n, i) => i === 0 ?
    <span key={i}>{n}.</span> :
    <small key={i}>{n}</small>);

  const emissionTitle = tx.stype === "emission" ? getEmissionTypeTitle(tx.obj.type) : null;

  const nickOnIcon = tx.fromUser ? tx.obj.to_nick : tx.sender_nick;

  const isTransfer = tx.stype === "transfer";

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        setOpen(!open);
      }}
      className={cx(styles.item, {
        [styles.open]: open,
        [styles.emission]: tx.stype === "emission",
        [styles.transfer]: isTransfer
      })}
    >

      <div className={styles.wrapper}>

        <div
          className={cx(styles.image)}
          style={{ background: isTransfer ? generateRandomGradient(nickOnIcon) : undefined }}
        >
          <img
            alt={"Avatar"}
            src={getUserIcon(`@${nickOnIcon}`)}
          />
          {isTransfer ?
            nickOnIcon[0] :
            <i className={({
              "emission": "fa fa-fw fa-cloud-upload-alt",
              "transfer": "fa fa-fw fa-exchange-alt"
            } as any)[tx.stype]}/>
          }
        </div>

        <div className={styles.meta}>
          <div className={styles.id}>
            {tx.stype !== "transfer" ?
              emissionTitle
              : <>{t(`tx.type.${tx.stype}`)} {t(tx.fromUser ? "tx.tx_to" : "tx.tx_from").toLowerCase()}&nbsp;
                <strong>{tx.fromUser ? (tx.obj && (tx.obj.to_nick ? `@${tx.obj.to_nick}` : tx.obj.to)) : (tx.sender_nick ? `@${tx.sender_nick}` : tx.sender_address)}</strong></>}
          </div>
          {tx.comment && tx.stype === "transfer" ?
            <div className={styles.comment}>
              {tx.comment}
            </div> : null}
        </div>

        <div className={styles.balance}>
          <div className={cx(styles.sum, {
            [styles.plus]: !tx.fromUser,
            [styles.minus]: tx.fromUser
          })}>
            {tx.fromUser ? "-" : "+"}{tx.obj && (Math.abs(tx.obj.amount)) >= (1e6) ?
            <React.Fragment>
              {sum(tx.obj.amount)}
            </React.Fragment> :
            <React.Fragment>
              {sum(tx.obj.amount * 1e6, undefined, undefined, true)} µMDC
            </React.Fragment>}
          </div>
          <div className={styles.time}>
            {format(tx.block_ts / 1000, "HH:mm", {
              locale: i18n.language === "ru" ? ruLocale : enLocale
            })}
          </div>
        </div>

      </div>

      <div
        className={styles.table}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <dl>
          <dt>TxID</dt>
          <dd>
            <a
              rel="noopener noreferrer"
              href={getExplorerLink(`tx/0x${tx.id}`)}
              target="_blank"
            >
              0x{tx.id}
            </a>
          </dd>

          <dt>{t("tx.date")}</dt>
          <dd>{format(tx.block_ts / 1000, "d MMMM yyyy (HH:mm:ss)", {
            locale: ruLocale
          })}</dd>

          <dt>{t("tx.amount")}</dt>
          <dd>{tx.fromUser ? "-" : "+"}{tx.obj && formatAmount(tx.obj.amount)} MDC</dd>

          {tx.stype === "transfer" ?
            <>
              <dt>{t("tx.from")}</dt>
              <dd>
                {tx.sender_nick ? <><strong>@{tx.sender_nick}</strong> <br/> {tx.sender_address}</> : tx.sender_address}
              </dd>
            </> : null}

          {tx.obj.to && tx.stype === "transfer" ?
            <>
              <dt>
                {t("tx.to")}
              </dt>
              <dd>
                {tx.obj.to_nick ? <><strong>@{tx.obj.to_nick}</strong> <br/> {tx.obj.to}</> : tx.obj.to}
              </dd>
            </> : null}

          {tx && tx.tx ?
            <>
              <dt>{t("tx.sum")}</dt>
              <dd>
                {formatAmount(tx.tx.balance)} MDC
              </dd>
            </> : null}

          {tx && tx.comment && tx.stype === "transfer" ?
            <>
              <dt>{t("tx.comment")}</dt>
              <dd>{tx.comment}</dd>
            </> : null}

        </dl>
      </div>

    </div>
  );
};

export { Transaction };
