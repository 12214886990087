import React from "react";

let isWasmInit = false;

const useWasm = () => {
  const [isWasmSupported, setWasmSupported] = React.useState(undefined as any);
  const [isInitializing, setInitializing] = React.useState(false);


  React.useEffect(() => {
    if(isWasmInit || isWasmSupported || isInitializing){
      return ()=>{

      };
    }
    setInitializing(true);
    const supported = (() => {
      try {
        if (typeof WebAssembly === "object"
          && typeof WebAssembly.instantiate === "function") {
          const module = new WebAssembly.Module(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));
          if (module instanceof WebAssembly.Module)
            return new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
        }
      } catch (e) {
      }
      return false;
    })();

    setWasmSupported(!!supported);

    if (!!supported) {
      // if (!WebAssembly.instantiateStreaming) { // polyfill
      const instantiateStreaming = async (resp: Promise<Response>, importObject: any) => {
        const source = await (await resp).arrayBuffer();
        return await WebAssembly.instantiate(source, importObject);
      };
      // }
      // @ts-ignore
      const go = new Go();
      console.info("initailizing go");
      instantiateStreaming(fetch("wasm/mdclib.wasm"), go.importObject)
        .then(async(result) => {
          go.run(result.instance);
          isWasmInit = true;
          setInitializing(false);
        });
    }else{
      setInitializing(false);
    }
  }, [isWasmSupported]);

  return ({ isWasmSupported });
};

export { useWasm };
